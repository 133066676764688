<template>
  <!-- Form for email submission -->
  <form @submit.prevent="submitForm" class="email-form form-wrapper">
    <input type="email" v-model="email" placeholder="Enter your email" class="email-input" required />
    <button class="button" type="submit">Join Now</button>
  </form>

  <!-- The div element to host the waitlist widget -->
  <div id="getWaitlistContainer" data-waitlist_id="20091" data-widget_type="WIDGET_2"></div>
</template>

<script>
import { db } from '@/firebase';
import { addDoc, collection, where, getDocs, query } from 'firebase/firestore';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async submitForm() {
      try {
        if (this.email) {
          // Query Firestore to check if the email already exists
          const emailQuery = query(
            collection(db, 'User-Waitlist'),
            where('email', '==', this.email)
          );

          const querySnapshot = await getDocs(emailQuery);

          if (!querySnapshot.empty) {
            // Email already exists
            toast.warn('This email is already in our list!', {
              position: 'top-center',
            });
          } else {
            // Email does not exist, so add it to Firestore
            await addDoc(collection(db, 'User-Waitlist'), { email: this.email });

            toast.success('Email submitted successfully!', {
              position: 'top-center',
            });

            this.email = ''; // Clear the email input
          }
        } else {
          toast.error('Please enter a valid email.', {
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error submitting email:', error);
        toast.error('Error submitting email. Please try again later.', {
          position: 'top-center',
        });
      }
    },

  },
};
</script>

<style scoped>
.email-form {
  display: flex;
  flex-direction: column;
}

.email-input {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}

input::placeholder {
  font-size: 20px;
}

/* Button styles */
.button {
  background: linear-gradient(135deg, #9c04bc 0%, #ffcf54 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 95, 109, 0.4);
  width: 25%;
}

.button:hover {
  background-color: #ff4a58;
  transform: scale(1.05);
}

/* Button alignment */
.form-wrapper {
  text-align: left;
}

@media (max-width: 1000px) {
  .button {
    padding: 15px 20px;
    text-align: left;
    width: 35%;
    font-size: 14px;
  }
}
</style>