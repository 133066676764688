<template>
  <div id="app">

    <main>
      <LandingPage />
      <FooterComp />
      <JoinNow />
    </main>

  </div>
</template>

<script>
import FooterComp from './components/FooterComp.vue';
import JoinNow from './components/JoinNow.vue';
import LandingPage from './views/LandingPage.vue';


export default {
  name: 'App',
  components: {
    LandingPage,
    FooterComp,
    JoinNow
  }
}
</script>

<style>
#app {
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
}

main {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
