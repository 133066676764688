// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBse84hoXlp2fQyw17R1idMy4do4_8XV3E",
  authDomain: "jayco-links.firebaseapp.com",
  projectId: "jayco-links",
  storageBucket: "jayco-links.appspot.com",
  messagingSenderId: "34639962263",
  appId: "1:34639962263:web:9be369e85d1bf11fe289d2",
  measurementId: "G-CQMZ7ELV36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db}