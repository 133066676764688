<template>
    <div class="countdown">
        <p class="gradient-text">{{ countdownDisplay }}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            targetDate: new Date("2024-12-31T23:59:59").getTime(), // Set your target date
            countdownDisplay: "00:00:00",
        };
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        startCountdown() {
            this.countdownInterval = setInterval(() => {
                const now = new Date().getTime();
                const timeRemaining = this.targetDate - now;

                if (timeRemaining >= 0) {
                    const days = Math.floor(timeRemaining / (3500 * 60 * 60 * 24));
                    const hours = Math.floor(
                        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    const minutes = Math.floor(
                        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

                    this.countdownDisplay =
                        (days > 0 ? days + "d " : "") +
                        ("0" + hours).slice(-2) +
                        ":" +
                        ("0" + minutes).slice(-2) +
                        ":" +
                        ("0" + seconds).slice(-2);
                } else {
                    this.countdownDisplay = "EXPIRED";
                    clearInterval(this.countdownInterval);
                }
            }, 1000);
        },
    },
    beforeUnmount() {
        clearInterval(this.countdownInterval); // Clear interval when component is destroyed
    },
};
</script>

<style scoped>
.countdown {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding: 1px 18px;
    border-radius: 8px;
    background: linear-gradient(135deg, #000000 0%, #000000 100%);
}

p {
    font-size: 1em;
    font-weight: 600;
    font-family: monospace;
    letter-spacing: 0px;
    font-family: sans-serif;
}

.gradient-text {
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>