import { createApp } from 'vue'; 
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPeopleLine, faChartLine, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

library.add(faPeopleLine, faChartLine, faHandHoldingHeart);


const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);


app.mount('#app');
