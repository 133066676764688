<template>
    <main>
        <nav :class="{ scrolled: isScrolled }">
            <div class="container">
                <div class="logo">
                    <button class="btn">
                        <span v-for="(char, index) in animatedText" :key="index" class="animated-letter"
                            :style="{ '--letter-index': index }">
                            {{ char === ' ' ? '\u00A0' : char }}
                        </span>
                    </button>
                </div>
            </div>
        </nav>
    </main>
</template>

<script>
export default {
    data() {
        return {
            isScrolled: false,
            animatedText: [], 
            textToAnimate: 'Join the waitlist now!', 
            textIndex: 0,
        };
    },
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        },
        typeText() {
            // Add a letter to animatedText at intervals
            if (this.textIndex < this.textToAnimate.length) {
                this.animatedText.push(this.textToAnimate[this.textIndex]);
                this.textIndex++;
                setTimeout(this.typeText, 150); // Delay between typing each letter
            // } else {
            //     // Restart typing after a delay
            //     setTimeout(() => {
            //         this.animatedText = [];
            //         this.textIndex = 0;
            //         this.typeText();
            //     }, 2000);
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.typeText();
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.typeText()
    },
}
</script>

<style scoped>
/* Navigation styles */
/* Navigation styles */
nav {
    background-color: #090909;
    color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: transform 0.8s ease-in-out;
    padding: 10px 0;
    transform: translateY(100%);
    pointer-events: none;
    background: linear-gradient(90deg, #ff7e5f, #feb47b);
    animation: changeBackground 10s infinite alternate;
}

/* Keyframes for cycling background images with smooth fade-in effect */
@keyframes changeBackground {
    0% {
        background: linear-gradient(135deg, #ffcf54 0%, #9c04bc 100%);
        background-blend-mode: screen;
    }

    100% {
        background: linear-gradient(135deg, #ffcf54 0%, #84029e 100%);
        background-blend-mode: screen;
    }
}



nav.scrolled {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.btn {
    padding: 10px 16px;
    font-size: 22px;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    /* border: 1px solid rgba(184, 180, 180, 0.686); */
    background-color: transparent;
    color: #ffffff;
    z-index: 99 !important;
    letter-spacing: 5px;
}


.animated-letter {
    display: inline-block;
    /* Needed for individual animations */
    opacity: 0;
    /* Start invisible */
    transform: translateY(20px);
    /* Start with a slight downward offset */
    animation: fadeInUp 0.5s forwards;
    /* Animation duration and mode */
    animation-delay: calc(0.1s * var(--letter-index));
    /* Delay per letter */
}

/* Keyframe animation for fade-in effect */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>