<template>
  <div class="wrapper">
    <!-- Navigation bar -->
    <nav :class="{ scrolled: isScrolled }">
      <div class="container">
        <div class="logo">
          <img alt="Rendezvouz Logo" src="@/assets/logo.png" />
          <div class="logo-text">
            <h1 class="gradient-text">Rendezvous</h1>
            <span>A place to meet</span>
          </div>
        </div>

        <div class="countdown">
          <CountingDown />
        </div>
      </div>
    </nav>

    <div class="hero-section">
      <div class="container">
        <!-- Left column with content -->
        <div class="content-column">
          <h1>
            Find Your Perfect Match, Browse Exclusive Deals, and Book
            Unforgettable Experiences—<span class="gradient-text">all in One Place.</span>
          </h1>
          <p>
            Get notified when you join the waitlist and be part of the
            <span class="gradient-text">Rendezvous </span>community
          </p>
          <div class="form-wrapper">
            <EmailForm />

          </div>

          <div class="highlight-columns">
            <div class="highlight-column">
              <h3>
                <font-awesome-icon icon="heart" class="icon" />Smart Algorithm
              </h3>
              <p>
                Our cutting-edge matchmaking algorithms intelligently pair you
                with your ideal companion.
              </p>
            </div>
            <div class="highlight-column">
              <h3>
                <font-awesome-icon icon="shopping-bag" class="icon" />Thriving
                Community
              </h3>
              <p>
                Become part of our vibrant and expanding community, where you'll
                engage with diverse individuals.
              </p>
            </div>
            <div class="highlight-column">
              <h3>
                <font-awesome-icon icon="calendar-alt" class="icon" />Perfect
                Matches
              </h3>
              <p>
                Embark on unforgettable journeys and adventures curated just for
                you.
              </p>
            </div>
          </div>
        </div>
        <!-- Right column with images -->
        <div class="image-column">
          <img src="@/assets/man.png" alt="Image 1" class="image-1" />
          <img src="@/assets/girl1.png" alt="Image 2" class="image-2" />
          <img src="@/assets/girl.png" alt="Image 3" class="image-3" />
        </div>
      </div>


      <div class="feature first" data-aos="fade-up">
        <div class="content" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1500">
          <h1>
            Find Your Perfect Match
          </h1>
          <p>Connect with people who share your interests, values, and goals. Whether you're looking for friendship,
            romance, or professional networking, our platform makes it easy to find meaningful connections.</p>
          <!-- <button class="btn">Learn more</button> -->
        </div>

        <!-- Right column with images -->
        <div class="feature-image" data-aos="fade-left" data-aos-offset="200" data-aos-delay="100"
          data-aos-duration="1500">
          <img src="@/assets/match-1.jpg" alt="Image 1" class="image-1" />
          <!-- <img src="@/assets/girl1.png" alt="Image 2" class="image-2" />
          <img src="@/assets/girl.png" alt="Image 3" class="image-3" /> -->
        </div>
      </div>

      <div class="feature second" data-aos="fade-up">
        <div class="feature-image" data-aos="fade-right" data-aos-offset="200" data-aos-delay="100"
          data-aos-duration="1500">
          <!-- <img src="@/assets/man.png" alt="Image 1" class="image-1" /> -->
          <img src="@/assets/support.jpg" alt="Image 2" class="image-2" />
          <!-- <img src="@/assets/girl.png" alt="Image 3" class="image-3" /> -->
        </div>
        <div class="content" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1500">
          <h1 data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1500">
            Professional Support Anytime
          </h1>
          <p>Whether you need guidance for mental well-being, career growth, or personal development, you can browse
            from qualified therapists and life coaches who are ready to support you.</p>
          <!-- <button class="btn">Learn more</button> -->
        </div>
      </div>

      <div class="feature third" data-aos="fade-up">
        <div class="content" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1500">
          <h1 data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1500">
            Discover Trusted Vendors and Services
          </h1>
          <p>From local artisans to top-rated service providers, find the vendors that meet your needs and enhance your
            lifestyle.</p>
          <!-- <button class="btn">Learn more</button> -->
        </div>

        <div class="feature-image" data-aos="fade-left" data-aos-offset="200" data-aos-delay="100"
          data-aos-duration="1500">
          <!-- <img src="@/assets/man.png" alt="Image 1" class="image-1" /> -->
          <!-- <img src="@/assets/girl1.png" alt="Image 2" class="image-2" /> -->
          <img src="@/assets/vendor.jpg" alt="Image 3" class="image-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CountingDown from "@/components/CountingDown.vue";
import EmailForm from "@/components/EmailForm.vue";


export default {
  components: {
    EmailForm,
    CountingDown
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
* {
  user-select: none;
  -webkit-user-drag: none;
}

/* General Styling */
/* Text styles */
h1,
p {
  font-family: "Roboto", sans-serif;
  text-align: left;
}

h1 {
  font-size: 50px;
  margin-bottom: 5px;
}

p,
h3 {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 30px;
}

/*  */
/*  */

.wrapper {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

/* Navigation styles */
nav {
  background-color: #fff;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
  padding: 10px 0;
  /* Smooth transition */
}

nav.scrolled {
  /* background-color: rgba(0, 0, 0, 0.938); */
  /* Background color after scroll */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px
}

.logo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.logo h1 {
  font-size: 1.5em;
  line-height: 1;
}

.logo-text {}

.logo span {
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 0 !important;
  display: none;
}

.logo img {
  width: 40px;
  height: auto;
}

/* Hero section styles */
.hero-section {
  padding: 100px 0;
  background-color: #fff;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.content-column {
  flex: 1;
  padding-right: 20px;
}

.image-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-column img {
  width: 250px;
  border-radius: 15px;
}

.image-column .image-1 {
  margin-bottom: -80px;
  margin-top: -20%;
  margin-right: 15%;
  transform: rotate(-35deg);
  z-index: 2;
}

.image-column .image-2 {
  margin-bottom: -10px;
  margin-top: -80px;
  margin-left: 80%;
  transform: rotate(25deg);
  z-index: 1;
}

.image-column .image-3 {
  margin-bottom: -50px;
  transform: rotate(8deg);
  margin-top: -90px;
  margin-right: 15%;
}

.gradient-text {
  background: linear-gradient(135deg, #ffcf54 0%, #9c04bc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Form styles */
.form-wrapper {
  margin-bottom: 30px;
}


/* Highlight columns styles */
.highlight-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 50px;
}

.highlight-column {
  text-align: left;
}

.highlight-column h3 {
  font-size: 15px;
  margin-bottom: 10px;
}

.highlight-column p {
  font-size: 12px;
  color: #666;
}

.icon {
  color: red;
  font-size: 20px;
  margin-right: 5px;
}

/* Screens lower then 1000px */
@media (max-width: 1000px) {
  nav .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  .hero-section .container {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
  }

  .image-column {
    display: none;
  }

  h1 {
    font-size: 38px;
  }

  .highlight-columns {
    display: flex;
    flex-direction: column;
    gap: 20%;
  }
}

@media (max-width: 768px) {
  .highlight-columns {
    display: none;
  }
}

.feature {
  height: 100vh;
  padding: 80px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  scroll-behavior: smooth;

}

@media (max-width: 768px) {
  .feature {
    /* display: none !important; */
  }
}

@media (max-width: 1200px) {
  .feature {
    padding: 0px !important;
    height: auto;
    margin-bottom: 60px !important;
  }
}

.btn {
  padding: 12px 16px;
  font-size: 28px;
  border-radius: 8px;
  border: none;
  border: 1px solid rgba(184, 180, 180, 0.686);
  background-color: #000;
  color: #fff;
}

.btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.first {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  text-align: left;
  margin: 40px 50px;
  border-radius: 20px;
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow: hidden !important;
}

.second {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  text-align: right;
  margin: 40px 50px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow: hidden !important;
}

.third {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  text-align: left;
  margin: 40px 50px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow: hidden !important;
}

.feature-image img {
  border-radius: 0 100px !important;
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.first h1,
.third h1 {
  text-align: left;
  font-size: 4em;
}

.second p {
  text-align: right;
}

.second h1 {
  font-size: 4em;
  text-align: right;
}

.content p {
  font-size: 2em;
  font-weight: 400;
  color: #504f4f;
}

@media (max-width: 1200px) {

  .first,
  .third {
    margin: 10px !important;
    margin-bottom: 80px !important;
    display: grid;
    grid-template-columns: 60% 40%;
    box-shadow: none !important;
    /* Adjust column widths */
  }

  .second {
    margin: 10px !important;
    margin-bottom: 50px !important;
    display: grid;
    grid-template-columns: 40% 60%;
    box-shadow: none !important;
  }

  .first img,
  .second img,
  .third img {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

@media (max-width: 768px) {

  .first,
  .second,
  .third {
    display: flex;
    flex-direction: column-reverse;
    box-shadow: none;
    margin: 0px !important;
  }

  .first h1,
  .second h1,
  .third h1 {
    font-size: 3em;
  }

  .feature p {
    font-size: 22px;
  }

  .first img, 
  .second img,
  .third img {
    width: 100% !important;
    border-radius: 0 100px !important;
  }

  .second {
    flex-direction: column !important;
    text-align: left !important;
    flex-direction: flex-start !important;
  }
}
</style>
