<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="footer-content">
                <a href="" class="footer-logo">
                    <img alt="Rendezvouz Logo" src="@/assets/logo.png" />
                    <span class="gradient-text">Rendezvous</span>
                </a>
                <ul class="footer-links">
                    <li><a href="#">About</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Licensing</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </div>
            <hr class="footer-divider" />
            <span class="footer-copyright">
                © 2023 <a href="https://flowbite.com/">Rendezvous™</a>. All Rights Reserved.
            </span>
        </div>
    </footer>
</template>

<style scoped>
.gradient-text {
    background: linear-gradient(135deg, #ffcf54 0%, #9c04bc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer {
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: sans-serif;
}

.footer-container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .footer-content {
        display: grid;
    }
}

.footer-logo {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-decoration: none;
    color: inherit;
}

.footer-logo img {
    height: 4em;
    width: 4em;
    /* Logo height */
    margin-right: 0.75rem;
    /* Spacing between logo and text */
}

.footer-logo span {
    font-size: 2.5rem;
    /* Text size */
    font-weight: 600;
    /* Bold text */
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    /* Remove list style */
    padding: 0;
    /* Remove padding */
    margin: 0;
    /* Remove margin */
}

.footer-links li {
    margin-right: 1.5rem;
    /* Spacing between items */
    margin-bottom: 1rem;
    /* Spacing for mobile view */
}

.footer-links li a {
    color: #6b7280;
    /* Gray text */
    text-decoration: none;
    /* No underline */
    font-size: 0.875rem;
    /* Text size */
}

.footer-links li a:hover {
    text-decoration: underline;
    /* Underline on hover */
}

.footer-divider {
    border: 0;
    /* Remove default border */
    height: 1px;
    /* Divider height */
    background-color: #e5e7eb;
    /* Light gray color */
    margin: 1.5rem 0;
    /* Margin top and bottom */
}

.footer-copyright {
    display: block;
    text-align: center;
    color: #6b7280;
    /* Gray text */
    font-size: 0.875rem;
    /* Text size */
}

.footer-copyright a {
    text-decoration: none;
    /* No underline */
    color: inherit;
    /* Inherit color */
}

.footer-copyright a:hover {
    text-decoration: underline;
    /* Underline on hover */
}
</style>